import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import ROLES from '../constants/roles';

// Create a new Axios instance with the base URL and Bearer token
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
});

type UserData = {
    userId: number;
    username: string;
    email: string;
    phone: string;
    image: string;
    cnic: string;
    city: string;
    token: string;
    type: string;
    created_at: string;
};

var mock = new MockAdapter(axios);

export function configureFakeBackend() {
    let users: UserData[] = [
        {
            userId: 5,
            username: 'Talha',
            email: 'talha@gmail.com',
            phone: '0321321923',
            image: '',
            cnic: '42205-232213-8',
            city: 'Karachi',
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwidHlwZSI6Im9wZXJhdG9yIiwiaWF0IjoxNzM0NjM0NDIwLCJleHAiOjE3MzQ2NjMyMjB9.yskZ6XORdmUDB7oDzXDdZQl9OJRmk2ZaAvDxJlm7j98',
            type: 'operator',
            created_at: '2024-10-31T20:26:09.000Z',
        },
    ];    

    mock.onPost('/login/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(async function () {
                try {
                    // Parse request payload
                    let params = JSON.parse(config.data);

                    // Make the actual API call to the login endpoint
                    const response = await axiosInstance.post('/login', {
                        identifier: params.email,
                        password: params.password,
                    });

                    // Check the `status` field in the API response
                    if (response.data.status === 'success' && ROLES.includes(response.data.data.type)) {
                        const { username, email, type: role, token } = response.data.data; // Destructure required fields
                        const filteredData = { username, email, role, token }; // Create a new object
                        resolve([200, filteredData]);
                    } 
                    else if (!ROLES.includes(response.data.data.type)) {
                        // Invalid credentials
                        resolve([401, { message: 'Access Denied.' }]);
                    } 
                    else if (response.data.status === 'error') {
                        // Invalid credentials
                        resolve([401, { message: response.data.message }]);
                    } else {
                        // Unexpected status
                        resolve([
                            500,
                            { message: 'Unexpected response from the server.' },
                        ]);
                    }
                } catch (error: any) {
                    // Handle network or server errors
                    resolve([
                        500,
                        { message: 'Invalid credentials' },
                    ]);
                }
            }, 1000); // Simulate delay
        });
    });

    mock.onPost('/register/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // add new users
                // let [firstName, lastName] = params.fullname.split(' ');
                // let newUser: UserData = {
                //     userId: users.length + 1,
                //     username: firstName,
                //     password: params.password,
                //     firstName: firstName,
                //     lastName: lastName,
                //     role: 'Admin',
                //     token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
                // };
                // users.push(newUser);

                // resolve([200, newUser]);
            }, 1000);
        });
    });

    mock.onPost('/forget-password/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.email === params.email;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let responseJson = {
                        message: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve([200, responseJson]);
                } else {
                    // else return error
                    resolve([
                        401,
                        {
                            message: 'Sorry, we could not find any registered user with entered username',
                        },
                    ]);
                }
            }, 1000);
        });
    });
}
